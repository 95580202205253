import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { withNamespaces } from "react-i18next";

import "../i18n";
import ContentLayout from "../components/contentLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKaggle,
  faTwitter,
  faGithub,
  faPython,
  faRProject,
  faDocker,
  faLinux,
  faReact,
} from "@fortawesome/free-brands-svg-icons";

import { faSquareRootAlt } from "@fortawesome/free-solid-svg-icons";

const ProjectsPage = ({ t, data }) => {
  return (
    <ContentLayout>
      <section className="section">
        <div className="container">
          <h1 className="has-text-weight-bold is-size-2 has-text-centered mb-5">
            {t("navbar:about")}
          </h1>
          <div className="columns is-multiline my-5">
            <div
              className="column is-offset-2-widescreen is-offset-1-desktop is-3-desktop is-2-widescreen is-4-tablet has-text-centered"
              id="profile"
            >
              <Img
                className="portrait"
                fluid={data.ceshine.childImageSharp.fluid}
                itemprop="image"
                alt="Avatar"
              />
              {/* <div className="portrait-title">
                <h2 itemprop="name">Ceshine Lee</h2>
              </div> */}
              <div className="profile-social">
                <a
                  href="https://kaggle.com/ceshine"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pr-3"
                >
                  <FontAwesomeIcon icon={faKaggle} className="big-icon" />
                </a>
                <a
                  href="https://github.com/ceshine"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pr-3"
                >
                  <FontAwesomeIcon icon={faGithub} className="big-icon" />
                </a>
                <a
                  href="https://twitter.com/ceshine_en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} className="big-icon" />
                </a>
              </div>
            </div>
            <div
              className="column is-6-widescreen is-7-desktop is-8-tablet"
              id="bio"
            >
              <div className="profile-title has-text-centered has-text-weight-bold">
                <span className="is-size-3">Ceshine Lee</span> /{" "}
                <span itemprop="jobTitle" className="is-size-4">
                  Founder
                </span>
              </div>
              <p className="is-size-5 mb-3">{t("about:profile-p1")}</p>
              <p className="is-size-5 mb-3">{t("about:profile-p2")}</p>
              <p className="is-size-5">{t("about:profile-p3")}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="section alt-bkg">
        <div className="container" id="why">
          <h1 className="has-text-weight-bold is-size-3 has-text-centered mb-6">
            {t("about:why")}
          </h1>
          <div className="columns my-5">
            <div className="column is-one-third has-text-centered">
              <Img
                fluid={data.contract.childImageSharp.fluid}
                itemprop="image"
                className="image"
                alt=""
              />
              <h2>{t("about:why-title-1")}</h2>
              <p>{t("about:why-desc-1")}</p>
            </div>
            <div className="column is-one-third has-text-centered">
              <Img
                fluid={data.agreement.childImageSharp.fluid}
                itemprop="image"
                className="image"
                alt=""
              />
              <h2>{t("about:why-title-2")}</h2>
              <p>{t("about:why-desc-2")}</p>
            </div>
            <div className="column is-one-third has-text-centered">
              <Img
                fluid={data.celebration.childImageSharp.fluid}
                itemprop="image"
                className="image"
                alt=""
              />
              <h2>{t("about:why-title-3")}</h2>
              <p>{t("about:why-desc-3")}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container" id="tech">
          <h1 className="has-text-weight-bold is-size-3 has-text-centered">
            {t("about:tech")}
          </h1>
          <div className="columns is-multiline is-mobile">
            <div className="column is-half-mobile is-one-quarter-tablet has-text-centered">
              <div className="icon">
                <FontAwesomeIcon icon={faPython} />
              </div>
              <h2 className="is-size-5">Python</h2>
            </div>
            <div className="column is-half-mobile is-one-quarter-tablet has-text-centered">
              <div className="icon">
                <FontAwesomeIcon icon={faRProject} />
              </div>
              <h2 className="is-size-5">R</h2>
            </div>
            <div className="column is-half-mobile is-one-quarter-tablet has-text-centered">
              <Img
                fluid={data.julia.childImageSharp.fluid}
                itemprop="image"
                alt="Julia"
                className="image-icon"
              />
              <h2 className="is-size-5">Julia</h2>
            </div>
            <div className="column is-half-mobile is-one-quarter-tablet has-text-centered">
              <div className="icon">
                <FontAwesomeIcon icon={faLinux} />
              </div>
              <h2 className="is-size-5">Linux</h2>
            </div>
            <div className="column is-half-mobile is-one-quarter-tablet has-text-centered">
              <Img
                fluid={data.sklearn.childImageSharp.fluid}
                itemprop="image"
                alt="Scikit-learn"
                className="image-icon"
              />
              <h2 className="is-size-5">Scikit-learn</h2>
            </div>
            <div className="column is-half-mobile is-one-quarter-tablet has-text-centered">
              <div className="icon">
                <FontAwesomeIcon icon={faSquareRootAlt} />
              </div>
              <h2 className="is-size-5">XGBoost / LightGBM</h2>
            </div>
            <div className="column is-half-mobile is-one-quarter-tablet has-text-centered">
              <Img
                fluid={data.tensorflow.childImageSharp.fluid}
                itemprop="image"
                alt="Tensorflow"
                className="image-icon"
              />
              <h2 className="is-size-5">Tensorflow 2.x</h2>
            </div>
            <div className="column is-half-mobile is-one-quarter-tablet has-text-centered">
              <Img
                fluid={data.pytorch.childImageSharp.fluid}
                itemprop="image"
                alt="PyTorch"
                className="image-icon"
              />
              <h2 className="is-size-5">PyTorch</h2>
            </div>
            <div className="column is-half-mobile is-one-quarter-tablet has-text-centered">
              <Img
                fluid={data.plotly.childImageSharp.fluid}
                itemprop="image"
                alt="Plotly"
                className="image-icon"
              />
              <h2 className="is-size-5">Plotly</h2>
            </div>
            <div className="column is-half-mobile is-one-quarter-tablet has-text-centered">
              <Img
                fluid={data.ggplot2.childImageSharp.fluid}
                itemprop="image"
                alt="ggplot2"
                className="image-icon"
              />
              <h2 className="is-size-5">ggplot2</h2>
            </div>
            <div className="column is-half-mobile is-one-quarter-tablet has-text-centered">
              <div className="icon">
                <FontAwesomeIcon icon={faReact} />
              </div>
              <h2 className="is-size-5">React</h2>
            </div>
            <div className="column is-half-mobile is-one-quarter-tablet has-text-centered">
              <div className="icon">
                <FontAwesomeIcon icon={faDocker} />
              </div>
              <h2 className="is-size-5">Docker</h2>
            </div>
          </div>
        </div>
      </section>
    </ContentLayout>
  );
};

export default withNamespaces(["about", "navbar"], {})(ProjectsPage);

export const query = graphql`
  query {
    ceshine: file(relativePath: { eq: "ceshine.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    julia: file(relativePath: { eq: "julia-logo-color.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pytorch: file(relativePath: { eq: "pytorch.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tensorflow: file(relativePath: { eq: "tensorflow.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    plotly: file(relativePath: { eq: "plotly.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sklearn: file(relativePath: { eq: "scikit-learn.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ggplot2: file(relativePath: { eq: "ggplot2.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agreement: file(relativePath: { eq: "undraw_agreement.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contract: file(relativePath: { eq: "undraw_contract.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    celebration: file(relativePath: { eq: "undraw_celebration.png" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
